import React from 'react'
import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import "./contact.css"
import ContactForm from "../components/common/contact-form"
import ContactDetails from "../components/common/contact_details"
import PopUp, {OpenSuccessPopUp} from "../components/common/pop-up"
import { ContactUsTags } from '../components/layout/strings'

const ContactUs = () => (
    <Layout selectedPage = "contactUs">
        <PopUp/>
        <SEO 
            title={ContactUsTags.title}
            description={ContactUsTags.description} />
        <div className="contactUsBanner">
            <p className="contactUsBannerQuestion">HEY WHATSUP?</p>
            <h1 id="contactUsBannerHeading" className="mainHeading">Take a second to tell us about your project</h1>
            <p id="contactUsBannerDescription" className="mainDescription">Have questions about your home interior project, our team is more than happy to answer all your queries</p>
        </div>
        <div className="contactUsContent">
                <ContactForm pageName = "contact-page" Action={()=>{OpenSuccessPopUp();}}/>

                <div className="contactUsDetails">
                    <h2 className="contactUsContentHeading">We are here for you, wearing our thinking caps </h2>
                    <p className="contactUsContentDescription">Leave us a message or contact us right away for all your home interior needs</p>
                    <ContactDetails color ="black"/>
                </div>
        </div>
    </Layout>
)

export default ContactUs
