import React, { Component } from "react";
import "./pop-up.css";

var isOpen=false;
class PopUp extends Component {
    render(){
    return (
    <div id="modal" className="modal" onClick={() => this.ClosePopUp()}>
      <div className="modal-content">
        <h3 className="sucessDialogHeading">Thank You for your response!</h3>
        <h6 className="sucessDialogInfo">Our designing team will get in touch with you soon.
                        Meanwhile Please browse our Designs from different themes so that we can be better informed about your taste.</h6>
      </div>
    </div>
    );
    }
  
    ClosePopUp(){
      if(isOpen){
        var popUp = document.getElementById("modal");
        popUp.style.opacity='0';
        setTimeout(() => {
          popUp.style.display='none';
        }, 500);
        isOpen=false;
      }
    }
  }

   export function OpenSuccessPopUp(){ 
    var popUp = document.getElementById("modal");
    popUp.style.display='block';
    setTimeout(() => {
      popUp.style.opacity='1';
    }, 1);
    isOpen = true;
  }

  export default PopUp;