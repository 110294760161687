import React, { Component } from "react";
import "./contact-form.css";

const formEmptyState = { name: "", email: "", phone: "", message: ""};
class ContactForm extends Component {
  constructor(props){
    super(props);
    this.state = formEmptyState;    
  }

  render() {
    const { name, email, phone, message } = this.state;
    return (
      <div className="form">
          <text>Drop your message</text>
          <form name="home-page" data-netlify-honeypot="bot-field" data-netlify="true" onSubmit={this.SubmitForm} autoComplete="off">
              <input type="hidden" name="form-name" value={this.props.pageName}/>
              <input type="hidden" name="bot-field" id="spambot-input"/>
              <TextInput Value={name} Name="name" Type="text" Placeholder="Name" HandleChange={this.HandleFormInputChange}/>
              <TextInput Value={email} Name="email" Type="email" Placeholder="Email address" HandleChange={this.HandleFormInputChange}/>
              <TextInput Value={phone} Name="phone" Type="tel" Placeholder="Phone number" HandleChange={this.HandleFormInputChange}/>
              <TextInput Value={message} Name="message" Type="text" Placeholder="Your message" HandleChange={this.HandleFormInputChange}/>
              <input type="submit" class="button" value="SEND"></input>
          </form>
      </div>
    );
  }

  encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  SubmitForm = e => {
    
    var hiddenField = document.getElementById("spambot-input");
    var isValid = hiddenField.value.length <= 0;
    if(isValid){
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({ "form-name": "home-page", ...this.state }),
      })
        .then(() => {
          this.SuccessAction();
          this.props.Action();
        })
        .catch(error => alert(error));
  
    }
    e.preventDefault();
  };

  HandleFormInputChange = e => this.setState({ [e.target.name]: e.target.value });

  SuccessAction() {
    var labels = document.getElementsByTagName("label");  

    this.setState(formEmptyState);

    for(var i=0; i< labels.length;i++){
      labels[i].classList.replace("labelFocused","label");   
      labels[i].style.color = "var(--text-color-inactive)";  
    }
  }
}

class TextInput extends Component{
  render(){
    var name = this.props.Name;
    return(
      <div className="textInput">
          <label for={name} className="label" id={name} name="label">{this.props.Placeholder}</label>
          <input className="input" type={this.props.Type} name={name} value={this.props.Value} onChange={this.props.HandleChange} onBlur={e => this.BlurInput(this.props.Name, e)} onFocus={e=> this.FocusInput(this.props.Name)} autoComplete="off" required/><br></br>
      </div>
    )
  }

  FocusInput = (labelClass)=>{
    var label = document.getElementById(labelClass);
    label.classList.replace("label","labelFocused"); 
    label.style.color = "var(--secondary-color)";
  } 

  BlurInput = (labelClass, e)=>{
    var label = document.getElementById(labelClass);    
    if(e.target.value == "")
    {
        label.classList.replace("labelFocused","label");
    } 
    label.style.color = "var(--text-color-inactive)";
  }
}

export default ContactForm;