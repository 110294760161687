
import React, { Component } from "react";
import "./contact_details.css";
import { Call, Mail, CallBlack, MailBlack } from "../../images/_imagesRootImport";
import { ContactDetailsText } from '../../components/layout/strings'

var callImage;
var mailImage;
class ContactDetails extends Component{
  constructor(props){
    super(props);
    callImage = props.color == "black" ? CallBlack : Call;
    mailImage = props.color == "black" ? MailBlack : Mail;
  }
  render(){
    return(
      <div className="contactDetailsContainer">
          <DetailsContainer id="phoneDetails" image={callImage} firstText={ContactDetailsText.phone[0]} secondText={ContactDetailsText.phone[1]} color={this.props.color}/>
          <DetailsContainer id="emailDetails" image={mailImage} firstText="" secondText={ContactDetailsText.email} color={this.props.color}/>    
      </div>
    )
  }
}   

var image;
class DetailsContainer extends Component{ 
    render(){
      return(
        <div id={this.props.id} className="contactDetails">
            <img className="contactDetailsIcon" src={this.props.image}/>
            <div className="contactDetailsTextContainer" style={{color : this.props.color}}>
                {this.Text(this.props.firstText)}
                {this.Text(this.props.secondText)}
            </div>
        </div>
      )
    }

    Text(text) {
        return <text className="contactDetailsText">{text}</text>;
    }
  }

  export default ContactDetails;